/*
femSense CSS
2018 by SteadySense
 */

// ../_src/fonts
@font-face {
    font-family: 'Basetica';
    src: url(../_src/fonts/Basetica-Light.woff2) format('woff2'),
        url(../_src/fonts/Basetica-Light.woff) format('woff'),
        url(../_src/fonts/Basetica-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basetica';
    src: url(../_src/fonts/Basetica-Medium.woff2) format('woff2'),
        url(../_src/fonts/Basetica-Medium.woff) format('woff'),
        url(../_src/fonts/Basetica-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Mixin

@mixin text-gradient($from, $to, $topercent) {
  color: $primary;
  background: -webkit-linear-gradient(0, $from, $to $topercent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2em; // fix cut off descenders
}

@mixin text-gradient-reset() {
  background: none;
  -webkit-text-fill-color: inherit;
  padding-bottom: 0em;
}

// Global

body {
  font-family: $primary-font-family;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  letter-spacing: 1px;
  line-height: 1.5;
  color: $text-color;
  background-color: #FFFFFF;
  display: flex;
  //min-height: 100vh;
  height: 100vh;
  flex-direction: column;
  -ms-overflow-x: hidden;
  overflow-x:hidden;
  //  position:relative;
}

main {
  width: 100%;
}

a {
  color: $link;
  text-decoration: none;

  &:hover {
    color: $text-highlight-color;
  }
}
p, ul, ol {
  margin-bottom: 1em;
}
::selection {
  background: $gradient-left-color;
  color: $block-primary-color;
}
::-moz-selection {
  background: $gradient-left-color;
  color: $block-primary-color;

}

blockquote {
  margin: 2rem 1rem;
  font-size: 1.125rem;
  font-weight: normal;

  cite {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }

  @media only screen and (min-width: $tablet) {
    margin: 3rem 3rem;
    font-size: 1.5rem;
  }
}

cite {
  font-style: normal;
  color: $text-light-color;
}

// Shared

.a50 {
  color: rgba(51,51,51,0.50);
}

.border-top {
  border-top:1px solid #e5e5e5;
}
.border-top-dark {
  border-top:1px solid #A0A0A066;
}
.border-bottom {
  border-bottom:1px solid #e5e5e5;
}
// Components & pages


.page {

  &__header {
  }

  &__action {
    color: $primary;
  }

  ul {
    padding-left: 40px;
    list-style: disc;
  }
  li {
    padding: 3px 0;
  }

  ol {
    padding-left:20px;

    li {
      padding-left:5px;
    }
  }
}

.intro {

  &__check {
    float:left;
    padding-right: 5px;
    width: 25px;

    @media only screen and (min-width: $tablet) {
      padding-right: 15px;
      width: 40px;
    }
  }

  &__checktext {
    display:flex;
    display:-webkit-box;

  }

    &__keywords {
      .column{
        @extend .has-text-pink-gradient;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          // IE10 up fix
          -webkit-text-fill-color: $primary;
        }
        @supports (-ms-ime-align: auto) {
          // MS Edge
          -webkit-text-fill-color: $primary;
        }
      }
    }
}

@-webkit-keyframes startanimation {
  0% {
    left: 0px;
  }
  100% {
    left: -1000rem;
  }
}
@-moz-keyframes startanimation {
  0% {
    left: 0px;
  }
  100% {
    left: -1000rem;
  }
}
@-ms-keyframes startanimation {
  0% {
    left: 0px;
  }
  100% {
    left: -1000rem;
  }
}
@keyframes startanimation {
  0% {
    left: 0px;
  }
  100% {
    left: -1000rem;
  }
}
.masterheader {
  opacity: 0.3;
  margin-top: 4rem;
  background-image: url(../_images/temperaturkurve.png);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  height: calc(86vh)!important;
  //height: calc(68vh)!important;
  width: auto;
  position: absolute;
  right: 0px;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 420s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay:0s;
  -webkit-animation-direction: normal;
  -webkit-animation-name: startanimation;

  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 420s;
  -moz-animation-timing-function: linear;
  -moz-animation-delay:0s;
  -moz-animation-direction: normal;
  -moz-animation-name: startanimation;

  -ms-animation-iteration-count: infinite;
  -ms-animation-duration: 420s;
  -ms-animation-timing-function: linear;
  -ms-animation-delay:0s;
  -ms-animation-direction: normal;
  -ms-animation-name: startanimation;

  -o-animation-iteration-count: infinite;
  -o-animation-duration: 420s;
  -o-animation-timing-function: linear;
  -o-animation-delay:0s;
  -o-animation-direction: normal;
  -o-animation-name: startanimation;

  animation-iteration-count: infinite;
  animation-duration: 420s;
  animation-timing-function: linear;
  animation-delay:0s;
  animation-direction: normal;
  animation-name: startanimation;
  @media only screen and (max-width: $tablet)  {
    background-size: calc(100vh);
    max-height: calc(28vh)!important;
  }
}

.navbar {
  transition: $transition-time;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: none;
  background-color: $block-primary-color;

  &__branding {
    z-index: 100;
    display: flex;
    align-items: center;
  }

  .navbar-brand {
    margin-left: 0 !important;
 //   a {
 //     z-index: 100;
 //     display: flex;
 //     align-items: center;

 //     img {
 //       height: 25px;
 //     }
 //   }
  }

  &__logo {
    width: 185px;
    transition: $transition-time;

    @media only screen and (max-width: $desktop) {
      &--inverted {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
  }

  &__link {
    font-size: $size-5;

    @media only screen and (min-width: $desktop) {
      margin-left: 43px;
      font-size: $size-6;

    }
    letter-spacing: 0.6px;
    transition: $transition-time;

    &--active {
      color: $dark-color !important;
      font-weight: 500;
    }
  }

  @media only screen and (min-width: $tablet) {
    padding-top: 15px;
  }

  @media only screen and (min-width: $desktop) {
    padding-top: 25px;
  }
  .navbar-item {
    padding: 1.1rem 0.75rem 0.8rem 0.75rem;
  }

  &__shrink:not(.is-active) {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid lighten($text-light-color, 43%);
    .navbar__logo {
      width: 159px;
      margin-right: 13px;
    }
    .navbar-item {
      padding: 1.3rem 0.75rem 0 0.75rem;
      line-height: 1.15;
    }
    .navbar-item.is-primary {
      padding-bottom: 1em;
    }
    .navbar-burger {
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }
    .lang-dropdown {
      transform: translateY(0);
    }

    .navbar-item.is-primary {
      box-sizing: border-box;
      border: 2px solid $primary;
    }
  }

  .navbar-menu ul {
    margin-bottom: 0;

    @media only screen and (min-width: $desktop) {
      display: flex;
    }
  }
}

// navbar mobile
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}



@media screen and (max-width: $desktop) {

  .navbar-start {
    overflow: hidden;
  }

  .navbar-menu {
    @has-hd-gradient;
  }

  .navbar-burger {
    position: fixed;
    top: 12px;
    right: 1.25rem;
    height: $button-height;
    width: $button-width;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease, transform .5s ease;
    transform: scale(0.5) translateY(24px);


    &:hover {
      opacity: .7;
    }
    .top {
      top: initial !important;
    }

    &.is-active {
      span {
        width: 23px;
        height: 1px;
      }
      .top {
        -webkit-transform: translateY(11px) translateX(0) rotate(45deg) !important;
        transform: translateY(11px) translateX(0) rotate(45deg) !important;

        background: #646464;
      }
      .middle {
        opacity: 0;
        background: $white;
      }

      .bottom {
        -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg) !important;
        transform: translateY(-11px) translateX(0) rotate(-45deg) !important;
        background: #646464;
      }
      -webkit-transform: scale(1) translateY(12px);
      transform: scale(1) translateY(12px);

    }

    span {
      background: $text-color-3;
      border: none;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: 11px;
      }

      &:nth-of-type(3) {
        top: 22px;
      }
    }
  }
  .navbar.is-fixed-top {
    max-height: calc(100vh - 2.25rem);
  }

  .navbar-menu {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transition: opacity .35s, visibility .35s, height .35s;
    z-index: 99;
    box-shadow: none !important;
    overflow: visible !important;
    padding: 0;
    color: #4a4a4a;

    &.is-active {
      opacity: 1;
      height: 20rem;

      li {
        animation: fadeInRight .5s ease forwards;
        animation-delay: .35s;
        opacity:0;

        &:nth-of-type(2) {
          animation-delay: .4s;
        }
        &:nth-of-type(3) {
          animation-delay: .45s;
        }
        &:nth-of-type(4) {
          animation-delay: .50s;
        }
        &:nth-of-type(5) {
          animation-delay: .55s;
        }
        &:nth-of-type(6) {
          animation-delay: .6s;
        }
      }
    }
    nav {
      position: relative;
      height: 78%;
      top: 22%;
      font-size: 50px;
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;

      li {
        display: block;
        height: 25%;
        height: calc(100% / 5);
        min-height: 50px;
        position: relative;

        a {
          display: block;
          position: relative;
          color: #4a4a4a;
          text-decoration: none;
          overflow: hidden;

          &:hover:after,
          &:focus:after,
          &:active:after {
            width: 100%;
          }

          &.button {
            padding: 0.85rem 0.75rem;
            //background: transparent;
            background-color: #00aeef;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 3px;
            background: $grey;
            transition: .35s;
          }
        }
      }
    }
  }

  .navbar-item:hover{
    color: #4a4a4a !important;
  }
}
.img-partners {
  @media only screen and (max-width: $tablet) {
    max-width: 30%;
  }
}
.img-press {
  @media only screen and (max-width: $tablet) {
    max-width: 30%;
  }
}
.blog {
  &__post {
    overflow: auto; // clear floats
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(90deg, rgba(190,75,145,0.3), rgba(243,152,153,0.3));
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      background: none;
    }
  }

  &__img {
    float: left;
    width: 6rem;

    @media only screen and (min-width: $tablet) {
      width: 10rem;
    }
  }

  &__date {
    color: $text-light-color;
    @media only screen and (max-width: $tablet) {
      line-height: 6px;
      padding-bottom: 10px;
      width: auto;
    }


  }

  &__link {
    &:hover {
      color: $link;
    }
  }

  &__title {
    color: $text-color;
    font-size: $size-5;
    padding-top: 0;
  }

  &__excerpt {
    color: $text-color;
  }

}


.post {

  &__meta {
    padding-bottom: 40px;
    margin-bottom: 40px;
    background: linear-gradient(90deg, rgba(190,75,145,0.3), rgba(243,152,153,0.3));
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
  }

  ul {

    list-style: disc;
    padding-left: 20px;
    padding-bottom: 25px;

  }

  #sources ~ p a, #quellen ~ p a {
    color: $text-color-2;
  }
  #sources ~ p a:hover, #quellen ~ p a:hover {
    color: $text-highlight-color;
  }


  .image {
    &__left {
      float: left;
      padding-right: 20px;
      padding-bottom: 15px;
      margin-bottom: -20px;

    }
    &__right {
      float: right;
      padding-left: 20px;
      padding-bottom: 15px;
      margin-bottom: -20px;

    }

  }

}


.lang-dropdown {
  display: block;
  position: absolute;
  top: 0.25rem;
  right: 2.75rem;
  transform: translateY(0.75rem);
  transition: transform .5s ease;

  .navbar-burger.is-active + & {
    transform: translateY(12px);
  }

  @media only screen and (min-width: $desktop) {
    position: relative;
    top: 0;
    right: 0;
    transform: none;
  }

  .lang.dropdown-trigger {
    height: 2.2rem;
    padding-right: 0;
    padding-left: 0;

    @media only screen and (min-width: $desktop) {
      padding-top: 0.9rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    .flag-icon {
      margin-right: 12px;
    }
  }

  .flag-icon {

    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: 17px;
    float:right;
    background-size: auto 100%;

    @media only screen and (max-width: $desktop) {
      right: 9px;
      position: absolute;
    }
  }

  .lang {

    background: none;
    border-color: transparent;
    padding: 0.5rem 0.75rem 0 0.75rem;
    width: 100%;
    font-weight: 200;
    display: inline-flex;
    font-size: $size-7;
    color: #646464;
    cursor: pointer;

    .text {

      width: 60px;
      line-height:27px;

    }

    &.dropdown {

      padding-left: 12px;
      padding-top: 3px;

      @media only screen and (min-width: $desktop) {
        padding-left: 33px;
      }

      a {
        font-size: $size-7;
        width: 103px;
        line-height:26px;
        color:#646464;

        @media only screen and (max-width: $desktop) {
          display: block;
        }

      }

      a:hover {
        color: $text-color;
      }

    }

  }

  .lang:hover {

    color: $text-color;

  }

  .dropdown-menu {
    width: 100vw;
    min-width: auto;
    max-width: 9.5rem;
    background: white;
    padding: 0.25rem 0.75rem 0.5rem 0.75rem;

    @media only screen and (min-width: $desktop) {
      width: auto;
      max-width: none;
    }
  }

  &__anker-down {

    background: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?> <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="612px" height="612px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" fill="rgb(150,150,150)" xml:space="preserve"> <g> <g id="_x31_0_34_"><g><path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782 c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296 c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/> </g> </g> </g> </svg>') no-repeat;
    background-size: 20px 17px;
    height: 17px;
    width: 20px;
    margin-right: 14px;
    transform: translateY(0.4rem);

  }

  &.hoverable:hover,
  &.hoverable.is-active {
    > .dropdown-menu {
      box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,0,0,0.1);

      @media only screen and (min-width: $desktop) {
        display: block;
      }
    }
  }
}

.footer {
  padding: 0 1rem;

  &__first {

    padding: 25px 0;

  }

  &__lang {

    @media only screen and (max-width: $tablet) {
      padding: 0 22px 0 40px;
    }

  }

  &__menu {

    &__title {

      font-weight: 100;
      font-size: 0.9rem;
      color: #4a4a4a;
      padding-bottom:10px;
      user-select: none;
      @media only screen and (max-width: $tablet) {
       padding-left: 2rem;
      }

      .footer__menu__payments {

        hyphens: auto;

      }

    }

    &__links, &__links a {

      color: rgba(64,64,64,0.6);
      font-size: $size-7;
      letter-spacing: 1px;
      line-height: 25px;
      text-decoration: none !important;

      transition: $transition-time;

      ifu {

        hyphens: auto;

      }

    }
    &-mobile a {
      margin-bottom: 0rem;
      padding: 0.1rem;
    }
    p {
      margin:0px;
    }

    &__links {
      margin-bottom: 0;
    }

    &__links a:hover {

      color: $text-color;

    }

  }

  &__copyright {
    opacity: 0.8;
    font-size: $size-7;
    color: $text-color-3;
    line-height: 5px;
    vertical-align: super;
  }

  &__social {
    &__image {
      height: 20px;
      padding: 0 5px;
      @media screen and (max-width: $tablet) {
        padding: 0 17px;
      }
    }
  }

  &__payments__image {
    background: white;
    width: auto;
    height: 1.75rem;

    &--border {
      border-radius: 3px;
      border: 2px solid #e4e4e4;
    }
  }
}




// Additional Classes
//
// Bulma Overwrite

.navbar-burger:hover{
  background-color: transparent;
}

.navbar-item{
  transition: $transition-time;
  padding:0.85rem 0.75rem;
}


// Accordion
.houdini-toggle {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline: none !important;
  padding: 0;
  width: 100%;
  letter-spacing: 1px;
  text-align: left;
  font-family: inherit;
  font-weight: inherit;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0 0 0;
}
.accordion,
.houdini-toggle {
  cursor: pointer;
}
.houdini-toggle-icon {
  height: 100%;
  display: block;
  margin-left: 0.75em;

  font-weight: 200;
  font-size: 1.5rem;
  color: $primary !important;
}

// Footer Menu
.footer__menu-mobile {
  margin: 0 -1rem;

  .houdini-toggle {
    @extend .footer__menu__title;
    padding-left: 0;
    padding-bottom: 0;
  }

  .houdini-toggle-icon {
    margin-right: 1rem;
  }

  a {
    display: block;
    padding: 0.75rem 0;
    margin-bottom: 0.25rem;
  }
}

.footer__menu-mobile .accordion-content {
  padding: 0 1.25em 1em 2.25em !important;
}

// Bulma radio-checkbox
.is-checkradio[type="radio"]:hover:not([disabled]) + label::before, .is-checkradio[type="radio"]:hover:not([disabled]) + label::after{
  border-color: gray !important;
}

.is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label::after{
  background: gray !important;
}

// Cookie Consent overwrite
.cc-window {
  z-index: 2000000001;  // To be above tawk.io which defaults to 2000000000
}
@media screen and (max-width: $tablet) {
  #cookieconsent\:desc {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .cc-window {
    padding: 18px;
    z-index: 9999;  // Reset tawk.io override
  }
  .cc-link {
    font-size: 14px;
  }
}

// Houdini Accordion
.houdini.is-expanded {
  display: block;
}

.banner {
  text-align: center;
  color: $block-secondary-color;
  margin-bottom: 0.5rem;
  height: 2.25rem;
  font-size:1.25rem;
  padding-top: 0.3rem;
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);

  @media only screen and (min-width: $desktop) {
    padding-top: 0.5rem;
    height: 3.0rem;
    font-size: 1.5rem;
  }
}
.career-img {
  opacity: 0.5;
}
.career-func {
    color: #000;
    font-size: 3rem;
    margin: 0px 0px;
    position: relative;
    left: -7rem;
    bottom: 2.25rem;
    font-weight: bold;
}
.team-img{
  max-width: 80%;
}

.slider-navigation-previous{
  margin-left: 0.1rem!important;
}

.slider-navigation-next{
  margin-right: 0.1rem!important;
}

.section{
  position:relative;
}

.grid-container-4 {
  display:grid;
  padding: 10px;
  grid-template-columns: 25% 25% 25% 25%;
}

.grid-container-3 {
  display:grid;
  padding: 10px;
  grid-template-columns: 33% 33% 33%;
}

.grid-item {
  text-align: center;
  margin:auto;
  //max-height: 50px;

}
.button:hover {
  border-color: transparent;
  color: white;
}
