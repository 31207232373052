// Styling for the footer
footer {
  padding: 25px 0;
  text-align: center;
  background-color: #f5f5f5;
  span.copyright {
    font-size: 90%;
    line-height: 40px;
    text-transform: none;
    @include heading-font;
  }
  a.telephone:before {
    display: inline-block;
    //line-height: 1.2;
    margin: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f095";
    color: #888;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  a.email:before {
    display: inline-block;
    //line-height: 1.2;
    margin: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0e0";
    color: #888;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  ul.quicklinks {
    font-size: 90%;
    line-height: 25px;
    margin-bottom: 0;
    text-transform: none;
    @include heading-font;
  }
  // Mobily friendlyness
  @media only screen and (max-width: $tablet) {
    .menuitem a {
      display: inline-block;
      padding: 0.75rem .6rem;
      margin-bottom: 0.5rem;
    }
    a.email,
    a.telephone {
      display: inline-block;
      padding: 0.6rem 0;
    }
  }
}

ul.social-buttons {
  margin-bottom: 20px;
  li {
    a {
      font-size: 20px;
      line-height: 40px;
      display: block;
      width: 40px;
      height: 40px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      color: white;
      border-radius: 100%;
      outline: none;
      background-color: $grey-light;
      &:active,
      &:focus,
      &:hover {
        background-color: $primary;
      }
    }
  }
}
a.footer__icons {
  background-color: black;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: $primary;
  }
}



.footer_new {
  text-align:center;
  padding-top: 10px;
  padding-bottom: 2rem;

  .footer_headline {
    padding-top: 20px;
    text-transform: uppercase;
  }
  .gotop {
    text-transform: uppercase;
    text-align: right;
    padding-right: 10px;
    font-weight: 800;
  }
  p {
    margin: 0;
    color: rgba(64,64,64,.6);
    font-size: 0.875rem;
    letter-spacing: 1px;
    line-height: 25px;
  }

  a {
    color: rgba(64,64,64,.6);
    font-size: 0.875rem;
  }

}
.social-img{
  height: 20px;
  width:auto;
  padding: 0 0.6rem;
  @media screen and (max-width: $tablet) {
    padding: 0 15px;
  }
}
