/*
femSense CSS
2018 by SteadySense
 */

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(7.5rem);
  }

  33% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.no-border {
  border-width:0px;
}
.home__product-img {
      width: 95%;
      height: auto;
      margin: auto auto;
      display: block;
      padding-bottom: 2rem;

      @media only screen and (max-width: $tablet)  {
        width: 100%;
      }
    }
.home__modal-img{
  width: auto;
  height: 50%;
  margin: auto auto;
  display: block;
  padding-bottom: 2rem;

  @media only screen and (max-width: $tablet)  {
    width: 100%;
  }
}
// for mobile
.home__main__headline {
  @media only screen and (max-width: $tablet) {
    text-align: center;
  }
}
.home__intro__headline {
  @media only screen and (max-width: $tablet) {
    text-align: center;
    font-weight: 400 !important;
  }
}
.description {
  @media only screen and (max-width: $tablet) {
    font-size: 1rem !important;
    margin-top: 40px !important;
  }
}
.description__solution {
  @media only screen and (max-width: $tablet) {
    font-size: 1rem !important;
    text-align: left;
  }
}
.text {
  @media only screen and (max-width: $tablet) {
    text-align: left;
    font-size: 1rem !important;
  }
}
.rel-col {
  position: relative;
}
.nfc-img {
  bottom: .75rem;
  position: absolute;
  max-width: 30vw!important;
  @media only screen and (max-width: $tablet) {
    max-width: 80vw!important;
    padding-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: unset;
  }
}
.career {
  @media only screen and (max-width: $mobile) {
    font-size: 1.5rem!important;
  }
}

.btn-new {
  border-radius:25px;
  border-color: transparent;
}
.home_list {
 list-style-type:disc;
}

.modal-content {
  width:90vw;
}

.press-img {
  width:90vw;
}
