@import url(//fonts.googleapis.com/css?family=Open+Sans:300,600);

logo-text {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.165em;
  color: #000000;
}

logo-text::before {
  content: "Steady";
  font-weight: 300;
}

logo-text::after{
  content: "Sense";
  font-weight: 600;
}
