@import '~bulma/sass/utilities/initial-variables';

// Variables
$gap: 32px;
$desktop: 1000px + (2 * $gap);
$widescreen-enabled: false;
$fullhd-enabled: false;
$section-padding: 1.5rem 1rem;
$section-padding-medium: 5rem 2rem;
$mobile: 500px;

// Colors
$dark: #333;
// $primary: #EE778D;
$primary: #008FDF;

$text-color: $dark;
$text-light-color: #787878;
$text-color-2: #A0A0A0;
$text-color-3: #646464;
$link: #c37742;
$text-highlight-color: #1ec09c;
$dark-color: #000;
$white: #FFF;

$link: $primary;
$text-highlight-color: #c4536a;

$block-primary-color: #F5F5F5;
$block-secondary-color: #FFF;

$gradient-left-color: #00cbd6;
$gradient-left-color2: #00cbd6;
$gradient-left-color3: #00d7cb;
$gradient-right-color: #3ce2b9;
$gradient-right-color2: #6beba2;
$gradient-right-color3: #99f188;

$gradient-left-color: #008FDF;
$gradient-left-color2: #008FDF;
$gradient-left-color3: #00d7cb;
$gradient-right-color: #3ce2b9;
$gradient-right-color2: #6beba2;
$gradient-right-color3: #99f188;

// Font-sizes
$size-1: 3.75rem;
$size-2: 3.125rem;
$size-3: 2.5rem;
$size-4: 1.875rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.875rem;


// Fonts
$system-font-family: Basetica,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$primary-font-family: $system-font-family;
$strong-weight: 500;


// Headings
$title-color: $text-color;
$title-weight: 200;
$title-line-height: 1.5;
$title-strong-weight: 400;
$subtitle-color: $text-color;
$subtitle-weight: 200;
$subtitle-line-height: 1.3;
$subtitle-negative-margin: -1rem;


// Transition
$transition-time: 0.4s;


// Navbar
$button-height: 26px;
$button-width: 35px;
$navbar-item-hover-color : $primary;
$navbar-item-hover-background-color : transparent;
$navbar-tab-active-color : $primary;
$navbar-breakpoint: $desktop;

// flag-icon-css
$flag-icon-css-path: '~flag-icon-css/flags' !default;


// Buttons
$button-color: $white;
$button-background-color: $primary;
$button-disabled-background-color: $grey;


$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: #00aeef !default;
$secondary: #49a0b5 !default;
$highlight: #e73364 !default;


// flag-icon-css
// $include controls which flag-icons are used - see docs
// $countries is set by us in webpack config,
$flag-icon-css-path: '~flag-icon-css/flags';
$include: $countries;
