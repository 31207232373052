.carousel {
  // Ensure content does't overflow carousel. See https://github.com/Wikiki/bulma-carousel/issues/86
  overflow: hidden;
  .slider {
	  outline: none!important;
    overflow: hidden;
  }
}

.carousel-item {
  width: 75%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.slider-page.is-active {
  background-color:#777777;
}
