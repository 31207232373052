@keyframes scroll-left {
  from {
    text-indent: 100%
  }
  to {
    text-indent: -35%
  }
}

@keyframes scroll-left-mobile {
  from {
    text-indent: 100%
  }
  to {
    text-indent: -150%
  }
}
