html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 72px;

  @media only screen and (min-width: $tablet) {
   	padding-top: 77px;
  }

  @media only screen and (min-width: $desktop) {
   	padding-top: 87px;
  }
}
